@import '../../../../scss/theme-bootstrap';

.header-offers-banner-item {
  font-family: $font--neue-haas-unica-pro;
  color: $color-light-black;
  overflow: hidden;
  text-align: center;
  width: auto;
  &__offer-text {
    display: inline;
    p,
    a {
      color: $color-light-black;
      margin: 0;
      font-size: 12px;
      font-weight: 400;
      line-height: get-line-height(12px, 18px);
    }
  }
}
